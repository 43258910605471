import React from "react";

function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        stroke="#D0D0D0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.25 11.25v-3.5c0-1.4 0-2.1.273-2.635.24-.471.62-.853 1.092-1.093.535-.272 1.235-.272 2.635-.272h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 011.092 1.093c.273.535.273 1.235.273 2.635v7c0 1.4 0 2.1-.273 2.635-.24.47-.622.853-1.092 1.092-.535.273-1.235.273-2.631.273H18.75m-7.5-7.5h-3.5c-1.4 0-2.1 0-2.635.273-.47.24-.853.622-1.093 1.092-.272.535-.272 1.235-.272 2.635v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.092c.534.273 1.234.273 2.631.273h7.009c1.396 0 2.095 0 2.63-.273a2.5 2.5 0 001.092-1.092c.273-.535.273-1.234.273-2.631V18.75m-7.5-7.5h3.5c1.4 0 2.1 0 2.635.273a2.5 2.5 0 011.092 1.092c.273.534.273 1.234.273 2.631v3.504"
      ></path>
    </svg>
  );
}

export default CopyIcon;
