import React from "react";

function LinkedinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#fff"
        d="M17.1.75c.664 0 1.25.586 1.25 1.29V17c0 .703-.586 1.25-1.25 1.25H2.06c-.663 0-1.21-.547-1.21-1.25V2.04c0-.704.547-1.29 1.21-1.29H17.1zm-10.977 15V7.43H3.545v8.32h2.578zM4.834 6.258c.82 0 1.485-.664 1.485-1.485 0-.82-.664-1.523-1.485-1.523-.859 0-1.523.703-1.523 1.523s.664 1.485 1.523 1.485zM15.85 15.75v-4.57c0-2.227-.508-3.985-3.125-3.985-1.25 0-2.11.703-2.461 1.367h-.039V7.43H7.764v8.32h2.578v-4.102c0-1.093.195-2.148 1.563-2.148 1.328 0 1.328 1.25 1.328 2.188v4.062h2.617z"
      ></path>
    </svg>
  );
}

export default LinkedinIcon;
