import React from "react";

function RocketIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#171B2F"
        fillRule="evenodd"
        d="M10.053 5.652l1.173-1.173c.944-.946 2.603-1.602 4.25-1.838a7.834 7.834 0 012.206-.03c.65.097 1.047.288 1.232.474.186.185.377.583.474 1.232.094.627.086 1.394-.03 2.206-.234 1.647-.891 3.306-1.837 4.25L11 17.297l-1.68-1.681a.733.733 0 10-1.04 1.037l2.2 2.2a.733.733 0 001.04 0l.802-.804 1.716 2.862a.732.732 0 001.148.142l2.933-2.933a.734.734 0 00.207-.624l-.682-4.77.915-.913c1.254-1.255 1.992-3.263 2.252-5.082.131-.92.146-1.833.027-2.631-.117-.775-.375-1.54-.886-2.052-.511-.51-1.277-.77-2.053-.886a9.288 9.288 0 00-2.63.03c-1.82.256-3.829.994-5.082 2.248l-.913.915-4.77-.682a.733.733 0 00-.623.207L.947 6.812A.733.733 0 001.09 7.96l2.86 1.716-.803.803a.734.734 0 000 1.038l2.2 2.2a.734.734 0 101.039-1.038l-1.682-1.68.949-.947 4.4-4.4zm4.759 13.699l-1.423-2.372 2.959-2.957.475 3.318-2.01 2.01h-.001zM5.02 8.609l2.957-2.957-3.317-.475-2.01 2.01 2.37 1.422zm-1.569 6.576a.734.734 0 00-1.037-1.038L.947 15.613a.734.734 0 001.039 1.038l1.467-1.467-.002.001zm2.2 2.2a.734.734 0 10-1.037-1.037l-2.933 2.934a.734.734 0 101.038 1.037l2.934-2.934H5.65zm2.2 2.2a.734.734 0 00-1.037-1.038l-1.467 1.466a.734.734 0 001.039 1.037l1.467-1.466-.002.001zm6.082-9.689a1.832 1.832 0 100-3.664 1.832 1.832 0 000 3.664z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default RocketIcon;
