import React from "react";

function YoutubeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="27"
      fill="none"
      viewBox="0 0 38 27"
    >
      <path
        fill="#fff"
        d="M36.942 4.92c.82 2.871.82 9.023.82 9.023s0 6.084-.82 9.024c-.41 1.64-1.71 2.87-3.281 3.281C30.72 27 19.1 27 19.1 27s-11.69 0-14.63-.752c-1.572-.41-2.87-1.64-3.281-3.281-.82-2.94-.82-9.024-.82-9.024s0-6.152.82-9.023C1.6 3.28 2.899 1.98 4.47 1.57c2.94-.82 14.63-.82 14.63-.82s11.62 0 14.56.82c1.572.41 2.87 1.71 3.28 3.35zm-21.67 14.56l9.707-5.537-9.707-5.537V19.48z"
      ></path>
    </svg>
  );
}

export default YoutubeIcon;
