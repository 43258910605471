import React from "react";

function WalletIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="19"
      fill="none"
      viewBox="0 0 23 19"
    >
      <path
        fill="#101B3A"
        d="M18.383 9.192a1.021 1.021 0 11-2.043 0 1.021 1.021 0 012.043 0z"
      ></path>
      <path
        fill="#101B3A"
        fillRule="evenodd"
        d="M9.134.255h3.179c1.877 0 3.364 0 4.527.157 1.197.161 2.166.5 2.93 1.264.945.945 1.246 2.209 1.355 3.85.59.259 1.035.807 1.083 1.501.005.063.005.13.005.19v3.949c0 .06 0 .127-.004.189-.05.694-.495 1.243-1.084 1.503-.11 1.64-.41 2.904-1.354 3.85-.765.763-1.734 1.102-2.931 1.264-1.164.156-2.65.156-4.527.156H9.134c-1.877 0-3.364 0-4.527-.157-1.197-.16-2.166-.5-2.931-1.264-.764-.765-1.103-1.734-1.264-2.93-.157-1.165-.157-2.651-.157-4.528v-.115c0-1.877 0-3.364.157-4.527.161-1.197.5-2.166 1.264-2.931C2.441.912 3.41.573 4.607.412 5.771.255 7.257.255 9.134.255zm10.442 12.766h-1.98c-2.19 0-4.065-1.662-4.065-3.83 0-2.167 1.875-3.83 4.065-3.83h1.979c-.117-1.369-.379-2.093-.889-2.601-.432-.432-1.024-.692-2.051-.83-1.05-.14-2.433-.143-4.38-.143H9.19c-1.948 0-3.33.002-4.382.143-1.026.138-1.618.398-2.05.83-.432.432-.692 1.024-.83 2.051-.14 1.05-.143 2.433-.143 4.38 0 1.948.002 3.33.143 4.382.138 1.026.398 1.618.83 2.05.432.432 1.024.692 2.051.83 1.05.14 2.433.143 4.38.143h3.064c1.948 0 3.332-.002 4.382-.143 1.026-.138 1.618-.398 2.05-.83.51-.508.773-1.231.89-2.602zM4.34 5.106a.766.766 0 01.766-.766h4.086a.766.766 0 110 1.532H5.106a.766.766 0 01-.766-.766zm16.008 1.788h-2.751c-1.455 0-2.534 1.081-2.534 2.298 0 1.216 1.08 2.297 2.533 2.297h2.776c.21-.013.3-.155.308-.24V7.134c-.007-.086-.098-.228-.308-.24h-.025l.001-.001z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default WalletIcon;
